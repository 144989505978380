import { GlobalColors } from "../../../../styles/Colors";
import laptop from "../../../../images/Laptop-Desk-Dark.png";

export const styles = {
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: GlobalColors.background,
        backgroundImage: `url(${laptop})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: GlobalColors.text,
    },
    containerOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: GlobalColors.background,
        opacity: 0.5,
    },
    imagesContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transition: 'width 0.5s ease-in-out'
    },
    image1: {
        position: "absolute", 
        top: "0", 
        left: "0", 
        width: "100%", 
        height: "100%", 
        opacity: 100,
        maskImage: `linear-gradient(to right, rgba(0, 0, 0, 0) ${50}%, rgba(0, 0, 0, 1) ${50}%)`,
        WebkitMaskImage: `linear-gradient(to right, rgba(0, 0, 0, 0) ${50}%, rgba(0, 0, 0, 1) ${50}%)`,
        maskSize: "100% 100%", 
        WebkitMaskSize: "100% 100%",
        objectFit: 'cover',
    },
    image2: {
        position: "absolute", 
        top: "0", 
        left: "0", 
        width: "100%", 
        height: "100%", 
        opacity: 100,
        maskImage: `linear-gradient(to left, rgba(0, 0, 0, 0) ${50}%, rgba(0, 0, 0, 1) ${50}%)`,
        WebkitMaskImage: `linear-gradient(to left, rgba(0, 0, 0, 0) ${50}%, rgba(0, 0, 0, 1) ${50}%)`,
        maskSize: "100% 100%", 
        WebkitMaskSize: "100% 100%",
        objectFit: 'cover',
    },
    // imageContainer: {
    //     width: '100%',
    //     height: '100%',
    //     position: 'relative',
    // },
    // image: {
    //     // position: "absolute",
    //     // top: 0,
    //     // left: 0,
    //     width: "100%",
    //     height: "100%",
    //     objectFit: "cover",
    //     transition: "transform 0.5s ease",
    // },
    // slider: {
    //     // position: "absolute",
    //     bottom: "20px",
    //     width: "100%",
    //     height: "5px",
    //     backgroundColor: "rgba(255, 255, 255, 0.5)",
    //     appearance: "none",
    //     outline: "none",
    //     borderRadius: "5px",
    //     WebkitAppearance: "none",
    //     opacity: "0.7",
    //     transition: "opacity 0.2s ease",
    // },
    // sliderThumb: {
    //     width: "20px",
    //     height: "20px",
    //     backgroundColor: "#fff",
    //     borderRadius: "50%",
    //     cursor: "pointer",
    //     WebkitAppearance: "none",
    //     appearance: "none",
    //     boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
    // },
    // sliderThumbActive: {
    //     width: "30px",
    //     height: "30px",
    //     boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
    // },
    detailsContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        // backgroundColor: 'yellow',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: GlobalColors.background,
        // backgroundImage: `url(${laptop})`,
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // color: GlobalColors.text,
    },
    detailsSection: {
        position: 'relative',
        width: '50%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // backgroundColor: GlobalColors.background,
        // backgroundImage: `url(${laptop})`,
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // color: GlobalColors.text,
    },
    detailsSectionHeader: {
        fontSize: '4rem',
    },
    designerSectionHeader: {
        fontSize: '4rem',
        fontFamily: 'Beanbag',
    },
    engineerSectionHeader: {
        fontSize: '4rem',
        fontFamily: 'Komika Sketch',
    },
    detailsPart1: {
        // backgroundColor: 'orange',
        // fontSize: '18px',
        fontFamily: 'Argentum Sans Light',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // backdropFilter: 'blur(2px)', /* adjust blur radius as desired */
    },
    detailsPart2: {
        // backgroundColor: 'lightblue',
        // fontSize: '18px',
        fontFamily: 'Argentum Sans Light',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // backdropFilter: 'blur(2px)', /* adjust blur radius as desired */

    },
    designerSectionSubHeader: {
        fontFamily: 'Argentum Sans Bold',
        color: 'gold',
        textTransform: 'uppercase',
        fontSize: '20px',
        opacity: 0.9,
    }
}