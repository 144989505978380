import React from 'react';

function Almanac() {
  return (
    <div>
      <h1>Almanac</h1>
      <p>This is the Almanac project.</p>
    </div>
  );
}

export default Almanac;
