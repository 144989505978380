import React from 'react';

function Webspase() {
  return (
    <div>
      <h1>Webspase</h1>
      <p>This is the Webspase project.</p>
    </div>
  );
}

export default Webspase;
