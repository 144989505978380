import { GlobalColors } from "../../../../styles/Colors";

export const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: GlobalColors.background,
        color: GlobalColors.text,
    },
    headerText: {
        fontSize: '3em',  
        fontFamily: 'Action Man',
        backgroundColor: 'purple',
    },
    bodyText: {
        fontSize: '4rem',
        fontFamily: 'Argentum Sans Light',
    },
    bodyEmphasisText: {
        fontSize: '4rem',
        fontFamily: 'Argentum Sans Black',
        // fontFamily: 'Comic Book Font',
        // fontFamily: 'Helvetica',
        fontWeight: 700,
        // color: GlobalColors.lightBlue,
    },
    button: {
        height: '10vh',
        width: '15vw',
        padding: 10,
        color: GlobalColors.primary,
        backgroundColor: 'transparent',
        border: '2px solid #ffffff',
        borderRadius: '60px',
        fontSize: '1.5rem',  
        textTransform: 'uppercase',

    },
    mediaContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#171717',
        color: GlobalColors.text,
    },
    mediaContainerOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: GlobalColors.background,
        background: 'linear-gradient(to top, black, rgba(0,0,0, 0.6))',
        // opacity: 1,
        // backdropFilter: 'blur(100px)', /* adjust blur radius as desired */
    },
    mediaTextOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(10px)', /* adjust blur radius as desired */
        // backgroundColor: GlobalColors.background,
        // opacity: 0.5,
    },
    mediaPlayer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#333333',
        // color: GlobalColors.text,
    },
}